import { type Link } from '../../api-models'
import { createHttpEndpoint } from '../../utils'

/**
 *
 * Get the list of internal links for a given page
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/link-booster-api/definition#operation/getInternalLinksForSourcePage}
 */
export const getSeoTags = createHttpEndpoint<GetSeoTagsResponse>({
  method: 'GET',
  operationId: 'getSeoTags',
  path: '/bm/tags',
})

export type GetSeoTagsResponse = Array<{
  title: string
  slug: string
  link: Link
}>
